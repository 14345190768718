/**
 *  Action MobX Store
 */
import { makeAutoObservable, runInAction } from "mobx";
import { Rush } from "types/rush";
import { Action } from "types/action";

export default class RushesStore {
  loading = false;
  list: Rush[] = [];
  action?: Action;

  constructor() {
    makeAutoObservable(this);
  }

  init(action: Action, videoScript: string) {
    console.info("[Store][RushesStore][init]");
    runInAction(() => {
      this.loading = true;
      this.action = action;
      this.list = [];
      videoScript
        .replace(/\n+/g, "\n")
        .split("\n")
        .map((script, index) => {
          const cleanedScript = script.trim();
          if (cleanedScript !== "") {
            this.list.push({
              index,
              script: cleanedScript,
            });
          }
        });
      this.loading = false;
    });
  }
}
