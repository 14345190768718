import Sentry from "./modules/sentry";
Sentry();

import React from "react";
import * as ReactDOM from "react-dom/client";
import "./styles/_reset.scss";
import "./styles/variables.ts";

import Root from "./containers/root";

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(<Root />);
