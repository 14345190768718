import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Colors, Spacing } from "../../styles/variables";
import Button from "../../components/button";
import { useStores } from "../../stores";

const Welcome = () => {
  const navigate = useNavigate();
  const { actionId } = useParams();
  const { ActionStore } = useStores();
  const [step, setStep] = useState(0);

  const steps = [
    {
      emoji: "🎥",
      title: "Hello ${user}",
      description: "Thanks for helping ${campaign}!",
    },
    {
      emoji: "📜",
      title: "Read the prompter",
      description: "Record each line of the script individually.",
    },
    {
      emoji: "🤳",
      title: "Hold your phone at eye level",
      description: "Trust us, it's your best angle ;)",
    },
    {
      emoji: "🔆",
      title: "Natural light?",
      description: "Yes, please! Stand facing a window or the sun.",
    },
    {
      emoji: "🔉",
      title: "Find a quiet spot",
      description:
        "No worries if you want to shoot on the street or in a park, just remember to use headphones.",
    },
    {
      emoji: "✨",
      title: "Ready?",
      description:
        "Chin up? Pout? Smile? You do you. Discover your script now!",
    },
  ];

  const goNext = () => {
    if (step < steps.length - 1) {
      setStep(step + 1);
    } else {
      skip();
    }
  };

  const goBack = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  };

  const skip = () => {
    navigate(`/${actionId}/presentation`);
  };

  const renderTitle = () => {
    const { activist } = ActionStore;
    const activistName = activist?.firstname || activist?.username || "";
    return steps[step].title.replace("${user}", activistName).trim();
  };

  const renderDescription = () => {
    const { campaign } = ActionStore;
    const campaignName = campaign?.name || "chilli";
    return steps[step].description.replace("${campaign}", campaignName);
  };

  const buttonLabel = () => {
    if (step === 0) {
      return "show me how";
    } else if (step < steps.length - 1) {
      return "next";
    } else {
      return "read script";
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        {step === 0 ? (
          <div> </div>
        ) : (
          <>
            <Button label="back" onClick={goBack} type="secondary" />
            <Button label="skip" onClick={skip} type="secondary" />
          </>
        )}
      </div>
      <div style={styles.content}>
        <div style={styles.emoji}>{steps[step].emoji}</div>
        <h1>{renderTitle()}</h1>
        <p>{renderDescription()}</p>
      </div>

      <div style={styles.footer}>
        <div style={styles.dots}>
          {steps.map((_, index) => (
            <div
              key={index}
              style={{
                ...styles.dot,
                ...(step === index ? styles.activeDot : {}),
              }}
            />
          ))}
        </div>
        <Button onClick={goNext} label={buttonLabel()} />
      </div>
    </div>
  );
};

const styles: any = {
  container: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    height: "100%",
    padding: Spacing.medium,
    paddingBottom: Spacing.small,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  emoji: {
    fontSize: 60,
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  footer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: Spacing.small,
  },
  dots: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: Spacing.small,
    marginBottom: Spacing.large,
  },
  dot: {
    width: 8,
    height: 8,
    borderRadius: 4,
    backgroundColor: Colors.Magenta[300],
  },
  activeDot: {
    backgroundColor: Colors.Magenta[700],
  },
};

export default Welcome;
