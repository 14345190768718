/**
 *  Root container
 */
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { StoreProvider } from "../../stores";
import App from "../app";
import Camera from "../recording_room/camera";
import Recap from "../recording_room/recap";
import ScriptPresentation from "../script_presentation";
import ThankYou from "../thank_you";
import Welcome from "../welcome";
import WrongAction from "../wrong_action";
import { observer } from "mobx-react";
import RecordingRoom from "../recording_room";

const RootRouter = () => {
  return (
    <StoreProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/wrong-action" element={<WrongAction />} />
          <Route path="/:actionId" element={<App />}>
            <Route index element={<Welcome />} />
            <Route
              path="/:actionId/presentation"
              element={<ScriptPresentation />}
            />
            <Route path="/:actionId/recording-room" element={<RecordingRoom />}>
              <Route
                path="/:actionId/recording-room/camera/:rushIndex?"
                element={<Camera />}
              />
              <Route
                path="/:actionId/recording-room/recap"
                element={<Recap />}
              />
            </Route>
            <Route path="/:actionId/thanks" element={<ThankYou />} />
          </Route>
          <Route path="*" element={<WrongAction />} />
        </Routes>
      </BrowserRouter>
    </StoreProvider>
  );
};

export default observer(RootRouter);
