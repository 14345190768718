/**
 *  Action MobX Store
 */
import { makeAutoObservable, runInAction } from "mobx";
import { getAction } from "../api/server";
import { Action } from "types/action";
import { Activist } from "types/activist";
import { Campaign } from "types/campaign";

export default class ActionStore {
  loading = false;
  action: Action | null = null;
  activist: Activist | null = null;
  campaign: Campaign | null = null;
  script: string | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  async fetch(actionId: string) {
    console.info("[Store][ActionStore][fetch]");
    try {
      runInAction(() => {
        this.loading = true;
      });
      if (!actionId) {
        throw new Error("No action id");
      }
      const { data: action } = await getAction(actionId);
      runInAction(() => {
        this.action = action;
        this.activist = action.activist;
        this.campaign = action.campaign;
        this.script = action.videoScript?.script;
      });
      return action;
    } catch (error) {
      console.error("[Store][ActionStore][fetch]", error);
      window.location.href = "/wrong-action";
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }
}
