import React from "react";

export default ({ ...props }) => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="#FF4BEB"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M54.793 1.16416C73.9565 19.5162 59.8784 47.2122 42.7936 45.5898L42.5146 46.2733L56.7501 52.2771C50.8819 59.4336 41.9731 64 31.9968 64C14.3258 64 0 49.673 0 32C0 14.327 14.3258 0 31.9968 0C35.7664 0 39.3824 0.65408 42.7405 1.85088L35.303 14.503L36.0006 14.8179C42.8026 7.99168 49.3997 13.975 54.793 1.16416Z" />
  </svg>
);
