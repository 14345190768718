import React, { useState } from "react";
import { Border, Colors, Spacing } from "../../styles/variables";
import LoadingAnimation from "../svg/loading-animation";

type ButtonType = {
  label: string;
  onClick: () => void;
  type?: "primary" | "secondary";
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
  disabled?: boolean;
};
const Button = ({
  label,
  onClick,
  type = "primary",
  startIcon,
  endIcon,
  disabled = false,
}: ButtonType) => {
  const [isPressed, setIsPressed] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onClickHandler = async () => {
    if (disabled || isLoading) {
      return;
    }
    setIsLoading(true);
    try {
      await onClick();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      onClick={onClickHandler}
      onTouchStart={() => setIsPressed(true)}
      onTouchEnd={() => setIsPressed(false)}
      style={{
        ...styles.container,
        ...(isPressed ? styles.pressed : {}),
        ...(disabled ? styles.disabled : {}),
        ...(type === "secondary" ? styles.secondary : {}),
      }}
    >
      {isLoading ? (
        <LoadingAnimation width={22} height={22} />
      ) : (
        <>
          {startIcon}
          {label}
          {endIcon}
        </>
      )}
    </div>
  );
};

const styles: any = {
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: Spacing.small,
    padding: Spacing.regular,
    borderRadius: Border.Radius.medium,
    backgroundColor: Colors.Black.secondary,
    fontSize: 16,
    fontWeight: "600",
    color: Colors.White.primary,
    cursor: "pointer",
    transition: "all 0.2s ease-in-out",
  },
  secondary: {
    backgroundColor: "transparent",
    fontWeight: "500",
    color: Colors.Grey[400],
  },
  pressed: {
    opacity: 0.8,
  },
  disabled: {
    opacity: 0.5,
    filter: "grayscale(1)",
  },
};

export default Button;
