import React from "react";
import { Border, Colors, Spacing } from "../../styles/variables";

import FaviconSVG from "../../components/svg/favicon";

const WrongDevice = () => {
  return (
    <div style={styles.container}>
      <div style={styles.content}>
        <h1>Uh-oh 🫣</h1>
        <br />
        <p>
          The link you opened is <span className="strong">not valid</span>.
        </p>
        <p>
          Please ask your contact to send you a{" "}
          <span className="strong magenta">new one</span>.
        </p>
        <div className="df-center" style={styles.footer}>
          <FaviconSVG width="12" height="12" fill={Colors.Grey[200]} />
        </div>
      </div>
    </div>
  );
};

const styles: any = {
  container: {
    display: "flex",
    height: "100%",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Colors.OffWhite.primary,
  },
  content: {
    padding: Spacing.large,
    borderRadius: Border.Radius.medium,
    backgroundColor: Colors.White.primary,
    width: "90%",
    maxWidth: 460,
  },
  footer: {
    marginTop: Spacing.large,
  },
};

export default WrongDevice;
