import axios from "axios";
import axiosRetry from "axios-retry";
import { Action } from "types/action";
import { Activist } from "types/activist";
import { Campaign } from "types/campaign";

const VIDEO_STUDIO_SERVER_PORT = 5004;
const BASE_URL = window.location.host.includes("localhost")
  ? `http://localhost:${VIDEO_STUDIO_SERVER_PORT}`
  : `https://video-studio-api.${window.location.host
      .split(".")
      .slice(1)
      .join(".")}`;

const videoStudioAxios = axios.create({
  baseURL: BASE_URL,
});
axiosRetry(videoStudioAxios, {
  retries: 3,
  retryDelay: axiosRetry.exponentialDelay,
  shouldResetTimeout: true,
});

/**
 * [POST] /v1/rushes/submit
 */
export const submitRushes = async (
  campaign: Campaign | null,
  action: Action | null,
  activist: Activist | null,
) => {
  await videoStudioAxios.post("/v1/rushes/submit", {
    campaign,
    action,
    activist,
  });
};

/**
 * [GET] /v1/livekit/access-token
 * Get LiveKit access token and WS url from server
 * @param actionId
 */
export const getLiveKitAccessToken = async (actionId: Action["id"]) => {
  const { data } = await videoStudioAxios.get(
    `/v1/livekit/access-token?actionId=${actionId}`,
  );
  return data;
};

/**
 * [POST] /v1/livekit/recording/start
 * @param roomName
 * @param filepath
 */
export const startLKEgress = async (
  roomName: string,
  filepath: string,
  audioTrackId: string,
  videoTrackId: string,
) => {
  const { data } = await videoStudioAxios.post(`/v1/livekit/recording/start`, {
    roomName,
    filepath,
    audioTrackId,
    videoTrackId,
  });
  return data;
};

/**
 * [POST] /v1/livekit/recording/stop
 * @param roomName
 */
export const stopLiveKitRecording = async (roomName: string) => {
  const { data } = await videoStudioAxios.post(`/v1/livekit/recording/stop`, {
    roomName,
  });
  return data;
};

/**
 * [POST] /v1/rushes
 */
type CreateRushParams = {
  actionId: string;
  position: number;
  scriptLine: string;
  lkEgressId: string;
  lkRecordingStartedAt: string;
  lkRecordingEndedAt: string;
};
export const createRush = async (params: CreateRushParams) => {
  const { data } = await videoStudioAxios.post(`/v1/rushes`, params);
  return data;
};
