/**
 *  Create React context and init hook useContext
 */
import React from "react";
import { useLocalObservable } from "mobx-react";

import ActionStoreType from "./action";
import RushesStoreType from "./rushes";

type RootStore = {
  ActionStore: ActionStoreType;
  RushesStore: RushesStoreType;
};
const StoreContext = React.createContext<RootStore | null>(null);

export const StoreProvider = ({ children }: any) => {
  const ActionStore = new ActionStoreType();
  const RushesStore = new RushesStoreType();
  const store = useLocalObservable<RootStore>(() => ({
    ActionStore,
    RushesStore,
  }));
  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};

export const useStores = (): RootStore => {
  const store = React.useContext(StoreContext);
  if (!store) {
    throw new Error("useStore must be used within a StoreProvider.");
  }
  return store;
};
