import React from "react";
import { Colors, Spacing } from "src/styles/variables";
import Favicon from "src/components/svg/favicon";
import "./style.scss";

const ThankYou = () => (
  <div style={styles.container}>
    <div style={styles.emoji}>🫶</div>
    <h1>Thank you</h1>
    <p>Your video is now in the AI editing room.</p>
    <p>Sit tight – our team will reach out to you shortly!</p>
    <p>You can leave this page 👋</p>
    <br />
    <br />
    <Favicon fill={Colors.Grey[200]} height={12} width={12} />
  </div>
);

const styles: any = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    height: "100%",
    padding: Spacing.medium,
    paddingBottom: Spacing.small,
    textAlign: "center",
  },
  emoji: {
    fontSize: 100,
    animation: "heartbeat 1.3s 4",
    rotate: "-5deg",
  },
};

export default ThankYou;
