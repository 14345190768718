import React, { useEffect, useState, memo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BsCheck } from "react-icons/bs";
import { CgRedo } from "react-icons/cg";
import { observer } from "mobx-react";
import "@livekit/components-styles";
import {
  VideoTrack,
  useTracks,
  useLocalParticipant,
  useRoomInfo,
  TrackLoop,
  TrackRefContext,
} from "@livekit/components-react";
import { TrackReference } from "@livekit/components-core";
import { Track } from "livekit-client";
import { EgressInfo } from "types/egress";
import { Border, Colors, Spacing } from "src/styles/variables";
import { useStores } from "src/stores";
import { createRush, startLKEgress } from "src/api/video-studio-server";
import LoadingAnimation from "src/components/svg/loading-animation";
import AudioVisualizer from "./components/audio_visualizer";
import "./style.scss";

const Camera = () => {
  const { ActionStore, RushesStore } = useStores();
  const { actionId, rushIndex } = useParams();
  const navigate = useNavigate();

  const [recordingStatus, setRecordingStatus] = useState<
    "initializing" | "ready" | "recording"
  >("initializing");
  const [lkEgressInfo, setLKEgressInfo] = useState<EgressInfo>();
  const [scriptLine, setScriptLine] = useState<string>();
  const [rushIdx, setRushIdx] = useState<number>(parseInt(rushIndex || "0"));
  const [startRecordingTime, setStartRecordingTime] = useState<Date>();

  const roomInfo = useRoomInfo();
  const {
    localParticipant,
    isMicrophoneEnabled,
    isCameraEnabled,
    lastCameraError,
    lastMicrophoneError,
    cameraTrack,
    microphoneTrack,
  } = useLocalParticipant();
  const trackReferences: TrackReference[] = useTracks([
    Track.Source.Camera,
    Track.Source.Microphone,
  ]);

  useEffect(() => {
    if (RushesStore.list[rushIdx]) {
      const readableLine = RushesStore.list[rushIdx].script.replace(
        /,/g,
        ",\n",
      );
      setScriptLine(readableLine);
    }
  }, [rushIdx]);

  useEffect(() => {
    if (!actionId) {
      navigate("/");
    }
  }, [actionId]);

  useEffect(() => {
    startLKRecording();
  }, [roomInfo?.name, microphoneTrack?.trackSid, cameraTrack?.trackSid]);

  useEffect(() => {
    if (lastCameraError) {
      window.alert(`Camera error\n${lastCameraError}`);
    }
    if (lastMicrophoneError) {
      window.alert(`Microphone error\n${lastMicrophoneError}`);
    }
  }, [lastCameraError, lastMicrophoneError]);

  /**
   * Start recording on LiveKit Egress by sending a request to the Video Studio Server
   */
  const startLKRecording = async () => {
    const roomName = roomInfo?.name;
    const audioTrackId = microphoneTrack?.trackSid;
    const videoTrackId = cameraTrack?.trackSid;
    if (!roomName || !audioTrackId || !videoTrackId) {
      return;
    }
    const campaignName = ActionStore.campaign?.name || "no_campaign";
    const activistUsername = ActionStore.activist?.username || "no_activist";

    const filePath =
      `campaign-rushes/${campaignName}/{room_name}/${activistUsername}/{time}.mp4`.replace(
        /[^a-zA-Z0-9\/\-\.{}]/g,
        "_",
      );
    const egressInfo = await startLKEgress(
      roomName,
      filePath,
      audioTrackId!,
      videoTrackId!,
    );
    setLKEgressInfo(egressInfo);
    setRecordingStatus("ready");
  };

  const startRecording = async () => {
    setStartRecordingTime(new Date());
    setRecordingStatus("recording");
  };

  const saveRush = async (startRecordingTime: Date | undefined) => {
    if (!lkEgressInfo) {
      console.warn("[saveRush] lkEgressInfo is undefined");
      return;
    }
    if (!startRecordingTime) {
      console.warn("[saveRush] startRecordingTime is undefined");
      return;
    }
    const actionId = ActionStore.action?.id || "";
    const position = rushIdx;
    const scriptLine = RushesStore.list[rushIdx].script;
    const lkEgressId = lkEgressInfo.egressId;
    const lkRecordingStartedAt = startRecordingTime.toISOString();
    const lkRecordingEndedAt = new Date().toISOString();
    await createRush({
      actionId,
      position,
      scriptLine,
      lkEgressId,
      lkRecordingStartedAt,
      lkRecordingEndedAt,
    });
  };

  const redoVideo = async () => {
    saveRush(startRecordingTime);
    setStartRecordingTime(undefined);
    setRecordingStatus("ready");
  };

  const nextVideo = () => {
    saveRush(startRecordingTime);
    setStartRecordingTime(undefined);
    setRecordingStatus("ready");
    if (
      rushIdx >= RushesStore.list.length - 1 ||
      typeof rushIndex !== "undefined"
    ) {
      navigate(`/${actionId}/recording-room/recap`);
    } else {
      setRushIdx((prev) => prev + 1);
    }
  };

  const VideoOverlay = memo(() => (
    <>
      <div
        style={{
          ...styles.header,
          fontSize: Math.max(
            18,
            Math.min(24 - (scriptLine?.length || 0) / 50, 24),
          ),
        }}
      >
        {scriptLine}
      </div>
      <div style={styles.skelleton}>
        <div style={styles.head} />
      </div>
      <div style={styles.footer}>
        {recordingStatus === "recording" && (
          <AudioVisualizer data-theme="audioVisualizer" />
        )}

        <div style={styles.footerButtons}>
          <div style={styles.footerButtonsSection}>
            {recordingStatus === "recording" ? (
              <div onClick={redoVideo}>
                <div style={styles.footerButtonsRedo}>
                  <CgRedo size={24} color={Colors.Black.secondary} />
                </div>
              </div>
            ) : (
              <div> </div>
            )}
          </div>
          <div style={styles.footerButtonsSection}>
            {recordingStatus === "initializing" && (
              <div style={styles.footerButtonsInitializing}>
                <LoadingAnimation
                  width={60}
                  height={60}
                  fill={Colors.White.transparent[50]}
                />
              </div>
            )}
            {recordingStatus === "ready" && (
              <div
                style={styles.footerButtonsRecord}
                onClick={startRecording}
              />
            )}
            {recordingStatus === "recording" && (
              <div style={styles.footerButtonsNext} onClick={nextVideo}>
                <BsCheck size={60} color={Colors.Green.primary} />
              </div>
            )}
          </div>
          <div style={styles.footerButtonsSection}>
            <div style={styles.footerButtonsSteps}>
              {rushIdx + 1}/{RushesStore.list.length}
            </div>
          </div>
        </div>
      </div>
    </>
  ));

  if (!localParticipant) {
    return (
      <div style={styles.loading}>
        <div style={styles.loadingEmoji}>🕺</div>
        initializing
      </div>
    );
  }
  if (!isMicrophoneEnabled) {
    return (
      <div style={styles.loading}>
        <div style={styles.loadingEmoji}>🎙️</div>Activating your microphone
      </div>
    );
  }
  if (!isCameraEnabled) {
    return (
      <div style={styles.loading}>
        <div style={styles.loadingEmoji}>🎥</div>Turning your camera on
      </div>
    );
  }
  if (recordingStatus === "initializing") {
    return (
      <div style={styles.loading}>
        <div style={styles.loadingEmoji}>🫶</div>You look amazing!
      </div>
    );
  }
  return (
    <div style={styles.container}>
      <TrackLoop tracks={trackReferences}>
        <TrackRefContext.Consumer>
          {(trackRef) => (
            <>
              <VideoOverlay />
              {trackRef?.source === Track.Source.Camera && (
                <VideoTrack
                  trackRef={trackRef as TrackReference}
                  style={styles.video}
                />
              )}
            </>
          )}
        </TrackRefContext.Consumer>
      </TrackLoop>
    </div>
  );
};

export const styles: any = {
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
  },
  loading: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    color: Colors.OffBlack.primary,
    fontWeight: 500,
  },
  loadingEmoji: {
    fontSize: 60,
    marginBottom: Spacing.medium,
  },
  popup: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    top: "10vh",
    left: 40,
    right: 40,
    zIndex: 20,
  },
  popupText: {
    color: Colors.Black.primary,
    fontSize: 18,
    fontWeight: 500,
    textAlign: "center",
  },
  video: {
    display: "block",
    margin: "0 auto",
    width: "100%",
    height: "100%",
    transform: "scaleX(-1)",
    objectFit: "cover",
    zIndex: 0,
    backgroundColor: Colors.Magenta[100],
  },
  header: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: Spacing.small,
    padding: `${Spacing.medium}px ${Spacing.medium}px`,
    fontSize: 22,
    textAlign: "center",
    color: Colors.White.primary,
    whiteSpace: "pre-line",
    backgroundColor: Colors.Black.transparent[50],
    backdropFilter: "blur(20px)",
    borderRadius: Spacing.small,
    zIndex: 20,
  },
  audioVisualizer: {
    position: "absolute",
    top: 5,
    right: 5,
    width: 30,
    height: 30,
    borderRadius: "500px",
    backgroundColor: Colors.Blue.primary,
    zIndex: 30,
  },
  footer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: `${Spacing.large * 2}px ${Spacing.medium}px`,
    zIndex: 20,
  },
  footerButtons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%",
  },
  footerButtonsSection: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "33%",
  },
  footerButtonsSteps: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 50,
    height: 50,
    fontSize: 12,
    fontWeight: 600,
    color: Colors.OffWhite.primary,
    backgroundColor: Colors.Black.transparent[50],
    borderRadius: Border.Radius.medium,
    fontVariantNumeric: "tabular-nums",
  },
  footerButtonsRedo: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 50,
    height: 50,
    borderRadius: 100,
    backgroundColor: Colors.White.transparent[50],
    border: `1px solid ${Colors.White.transparent[25]}`,
  },
  footerButtonsInitializing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 80,
    height: 80,
    borderRadius: "50%",
    backgroundColor: Colors.White.transparent[25],
    border: `4px solid ${Colors.White.transparent[50]}`,
  },
  footerButtonsRecord: {
    width: 80,
    height: 80,
    borderRadius: "50%",
    backgroundColor: Colors.Red.primary,
    border: "4px solid white",
  },
  footerButtonsNext: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 80,
    height: 80,
    borderRadius: "50%",
    backgroundColor: Colors.White.primary,
    border: "4px solid white",
  },
  skelleton: {
    position: "absolute",
    top: 110,
    left: 0,
    right: 0,
    zIndex: 10,
  },
  head: {
    width: "70vw",
    height: "80vw",
    maxWidth: "45vh",
    maxHeight: "50vh",
    borderRadius: "50%",
    border: `1px solid ${Colors.White.transparent[25]}`,
    boxShadow: `0 0 100px ${Colors.Black.transparent[25]}`,
    margin: "0 auto",
  },
};

export default observer(Camera);
