export const Colors = {
  Black: {
    primary: "#000000",
    secondary: "#140F14",
    transparent: {
      25: "rgba(0, 0, 0, 0.25)",
      50: "rgba(0, 0, 0, 0.5)",
      75: "rgba(0, 0, 0, 0.75)",
    },
  },
  OffBlack: {
    primary: "#140F14",
  },
  White: {
    primary: "#FFFFFF",
    transparent: {
      25: "rgba(255, 255, 255, 0.25)",
      50: "rgba(255, 255, 255, 0.5)",
      75: "rgba(255, 255, 255, 0.75)",
    },
  },
  OffWhite: {
    primary: "#F5F5F5",
  },
  Grey: {
    primary: "#4D4B4C",
    25: "#FFFBFF",
    50: "#F2EEF2",
    100: "#E5E2E5",
    200: "#CCC9CC",
    300: "#B2B0B2",
    400: "#999799",
    500: "#807E80",
    600: "#5E5E5E",
    700: "#4D4B4C",
    800: "#323132",
    900: "#121212",
  },
  Magenta: {
    primary: "#FF4BEB",
    50: "#FFF6FE",
    100: "#FFEDFD",
    200: "#FFE3FC",
    300: "#FFC9F9",
    400: "#FFB0F6",
    500: "#FF96F4",
    600: "#FF7DF1",
    700: "#FF63EE",
    800: "#FF4BEB",
    900: "#E544D6",
    1000: "#CC3DBE",
    1100: "#B235A6",
    1200: "#992D8E",
    1300: "#802577",
    1400: "#661E5F",
  },
  Red: {
    primary: "#F43D3D",
    25: "#F4E8E8",
    50: "#F4DCDC",
    100: "#F4C3C3",
    200: "#F48686",
    300: "#F46E6E",
    400: "#F45555",
    500: "#F43D3D",
    600: "#BF281C",
    700: "#A62218",
    800: "#8C1D14",
    900: "#7A271A",
  },
  Green: {
    primary: "#3FC486",
    25: "#CBF8E3",
    50: "#B3F8D8",
    100: "#99F8CC",
    200: "#81F8C0",
    300: "#68F8B5",
    400: "#4FF8A9",
    500: "#47DE98",
    600: "#3FC486",
    700: "#37AB75",
    800: "#2F9163",
    900: "#277852",
  },
  Blue: {
    primary: "#2E90FA",
    25: "#F5FAFF",
    50: "#EFF8FF",
    100: "#D1E9FF",
    200: "#B2DDFF",
    300: "#84CAFF",
    400: "#53B1FD",
    500: "#2E90FA",
    600: "#1570EF",
    700: "#175CD3",
    800: "#1849A9",
    900: "#194185",
  },
};

export const Spacing = {
  tiny: 4,
  small: 8,
  regular: 12,
  medium: 16,
  large: 24,
  huge: 32,
};

export const Border = {
  Radius: {
    tiny: 4,
    small: 8,
    medium: 12,
    large: 16,
  },
};
