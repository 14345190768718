import React from "react";

export default ({ ...props }) => (
  <svg
    width="980"
    height="1000"
    viewBox="0 0 980 1000"
    fill="#140F14"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M872.17 810.66L872.18 810.65V810.67L872.17 810.66ZM9.03052e-07 494.75C9.03052e-07 926.52 537.29 1205.03 872.17 810.67L820.19 756.74C937.09 666.49 980 553.98 980 441.05H902.76C1024.37 285.34 965.22 66.3402 867.77 0.000243566C810.78 142.06 646.01 108.77 646.01 108.77L664.36 34.9402C332.17 -90.3898 -0.0199991 134.96 9.03052e-07 494.75ZM397.13 158.29C210.96 207.83 109.52 391.53 161.37 584.36L161.35 584.34C213.21 777.2 390.61 886.72 573.23 838.14C700.02 804.42 769.42 703.05 769.42 703.05L644.37 573.66C621.68 612.86 584.45 645.51 541.69 656.88C456.18 679.63 371.23 626.35 346.67 535.07C322.13 443.78 369.02 355.44 454.54 332.69C497.3 321.31 546.21 329.27 585.5 354.75L628.93 180.53C628.93 180.53 520.37 125.51 397.13 158.29Z"
    />
  </svg>
);
