import React from "react";
import { CgRedo } from "react-icons/cg";
import { useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react";

import { Rush } from "types/rush";
import { Border, Colors, Spacing } from "src/styles/variables";

type RushBlockType = {
  rush: Rush;
};
const RushBlock = ({ rush }: RushBlockType) => {
  const navigate = useNavigate();
  const { actionId } = useParams();

  const onClickHandler = () => {
    navigate(`/${actionId}/recording-room/camera/${rush.index}`);
  };

  return (
    <div style={styles.container} onClick={onClickHandler}>
      <div style={styles.rush}>
        <div style={styles.rushScript}>{rush.script}</div>
      </div>
      <div style={styles.cta}>
        <CgRedo color={Colors.Grey[200]} />
      </div>
    </div>
  );
};

const styles: any = {
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: Spacing.small,
    marginBottom: Spacing.medium,
    padding: Spacing.small,
    backgroundColor: Colors.White.primary,
    border: `1px solid ${Colors.Grey[50]}`,
    borderRadius: Border.Radius.medium,
  },
  rush: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    gap: Spacing.small,
  },
  rushScript: {
    fontSize: 12,
    fontWeight: 400,
  },
  cta: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: Spacing.small,
  },
};

export default observer(RushBlock);
