import React, { memo } from "react";
import ChilliLogo from "src/components/svg/logo";
import { Colors } from "src/styles/variables";

const SplashScreen = memo(() => (
  <div style={styles.container}>
    <ChilliLogo fill={Colors.Black.secondary} width="84" height="84" />
    <h1>Studio</h1>
  </div>
));

const styles: any = {
  container: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    backgroundColor: Colors.Magenta.primary,
  },
};

export default SplashScreen;
