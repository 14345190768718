import axios from "axios";
import axiosRetry from "axios-retry";

const API_SERVER_PORT = 5003;
const BASE_URL = window.location.host.includes("localhost")
  ? `http://localhost:${API_SERVER_PORT}`
  : `https://api.${window.location.host.split(".").slice(1).join(".")}`;

const apiAxios = axios.create({
  baseURL: BASE_URL,
});
axiosRetry(apiAxios, {
  retries: 3,
  retryDelay: axiosRetry.exponentialDelay,
  shouldResetTimeout: true,
});

/**
 * Get action info from server
 */
export const getAction = async (id: string) => {
  const { data } = await apiAxios.get(`/public/actions/${id}/script`);
  return data;
};
