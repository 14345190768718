import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react";

import { Colors, Spacing } from "src/styles/variables";
import { useStores } from "src/stores";
import Button from "src/components/button";
import Favicon from "src/components/svg/favicon";
import RushBlock from "./components/rush_block";
import { submitRushes } from "src/api/video-studio-server";

const Recap = () => {
  const navigate = useNavigate();
  const { actionId } = useParams();
  const { ActionStore, RushesStore } = useStores();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmitHandler = async () => {
    try {
      setIsSubmitting(true);
      await submitRushes(
        ActionStore.campaign,
        ActionStore.action,
        ActionStore.activist,
      );
      navigate(`/${actionId}/thanks`);
    } catch (error) {
      console.log(error);
      setIsSubmitting(false);
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <img style={styles.campaignLogo} src={ActionStore.campaign?.imageUrl} />
        <div style={styles.campaignName}>{ActionStore.campaign?.name}</div>
      </div>
      <div style={styles.callout}>
        <p>
          <strong style={{ color: Colors.Magenta.primary, fontWeight: 600 }}>
            💕
            <br />
            Thank you so much
          </strong>
        </p>
        <p style={{ fontSize: 13 }}>
          Tap on any line to re-record it,
          <br />
          or click on <span style={styles.strong}>submit</span> if you're happy
          with it!
        </p>
      </div>
      <div style={styles.rushes}>
        {RushesStore.list.map((rush, index) => (
          <RushBlock key={`${index}-${rush.script}`} rush={rush} />
        ))}
      </div>
      <div style={styles.footer}>
        <Button
          label="submit"
          onClick={onSubmitHandler}
          startIcon={
            <Favicon fill={Colors.Magenta.primary} height={12} width={12} />
          }
          disabled={isSubmitting}
        />
      </div>
    </div>
  );
};

const styles: any = {
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    flex: 1,
    backgroundColor: Colors.OffWhite.primary,
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: Spacing.small,
    margin: Spacing.medium,
  },
  campaignLogo: {
    width: 32,
    height: 32,
    borderRadius: 20,
  },
  campaignName: {
    fontSize: 16,
    fontWeight: 600,
  },
  callout: {
    margin: `${Spacing.regular}px ${Spacing.medium}px 0`,
    fontSize: 13,
    color: Colors.Grey[500],
    textAlign: "center",
  },
  strong: {
    fontWeight: 600,
  },
  rushes: {
    padding: Spacing.medium,
    flex: 1,
    overflowY: "scroll",
  },
  footer: {
    padding: Spacing.medium,
  },
};

export default observer(Recap);
