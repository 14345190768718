import React, { useEffect, useState } from "react";
import { BsFillRecordBtnFill } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react";
import { useStores } from "../../stores";
import Button from "../../components/button";
import { Border, Colors, Spacing } from "../../styles/variables";

const ScriptPresentation = () => {
  const { ActionStore, RushesStore } = useStores();
  const { actionId } = useParams();
  const [ctaDisabled, setCtaDisabled] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    const timeout = setTimeout(() => {
      setCtaDisabled(false);
    }, 1000);
    return () => clearTimeout(timeout);
  }, []);

  const estimateReadingTime = () => {
    if (!ActionStore.script) {
      return 1;
    }
    const wordsPerMinute = 120;
    const words = ActionStore.script.split(/\s+/).filter(Boolean);
    return Math.ceil(words.length / wordsPerMinute);
  };

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <img style={styles.campaignLogo} src={ActionStore.campaign?.imageUrl} />
        <div>
          <div style={styles.campaignName}>{ActionStore.campaign?.name}</div>
          <div style={styles.readTime}>
            {estimateReadingTime()} min. estimated recording time
          </div>
        </div>
      </div>
      <div style={styles.instructions}>
        You will record each line from the following script, one at a time :)
      </div>
      <div style={styles.script}>
        <div style={styles.scriptHeader}>Script preview:</div>
        {RushesStore.list.map((rush) => (
          <p key={`${rush.index}-${rush.script}`}>{rush.script}</p>
        ))}
      </div>
      <div style={styles.footer}>
        <Button
          label="start recording"
          onClick={() => {
            navigate(`/${actionId}/recording-room/camera`);
          }}
          startIcon={<BsFillRecordBtnFill color={Colors.Magenta.primary} />}
          disabled={ctaDisabled}
        />
      </div>
    </div>
  );
};

const styles: any = {
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    flex: 1,
    backgroundColor: Colors.OffWhite.primary,
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: Spacing.small,
    margin: Spacing.medium,
  },
  campaignLogo: {
    width: 32,
    height: 32,
    borderRadius: 20,
  },
  campaignName: {
    fontSize: 16,
    fontWeight: 600,
  },
  readTime: {
    fontSize: 12,
    color: Colors.Grey[400],
  },
  scriptHeader: {
    marginBottom: Spacing.regular,
    fontSize: 14,
    fontWeight: 600,
    color: Colors.Black.primary,
  },
  script: {
    flex: 1,
    overflowY: "scroll",
    padding: Spacing.medium,
    borderBottom: `1px solid ${Colors.Grey[50]}`,
    whiteSpace: "pre-line",
    color: Colors.Black.secondary,
  },
  footer: {
    padding: Spacing.medium,
  },
  instructions: {
    margin: `${Spacing.regular}px ${Spacing.medium}px 0`,
    padding: `${Spacing.small}px ${Spacing.regular}px`,
    backgroundColor: Colors.Magenta[50],
    fontSize: 14,
    color: Colors.Grey[700],
    borderRadius: Border.Radius.medium,
    border: `1px solid ${Colors.Magenta.primary}`,
  },
};

export default observer(ScriptPresentation);
