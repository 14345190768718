import React, { useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import { observer } from "mobx-react";

import useNetworkChange from "src/hooks/useNetworkChange";
import { Colors, Spacing } from "src/styles/variables";
import { useStores } from "src/stores";
import SplashScreen from "./components/splashscreen";

const App = () => {
  const isOnline = useNetworkChange();
  const { actionId } = useParams();
  const [isAppLoading, setIsAppLoading] = useState(true);
  const { ActionStore, RushesStore } = useStores();

  const fetchAction = async (actionId: string) => {
    const action = await ActionStore.fetch(actionId);
    if (action && ActionStore.script) {
      await RushesStore.init(action, ActionStore.script);
    }
  };

  useEffect(() => {
    if (actionId) {
      fetchAction(actionId);
    }
  }, [actionId]);

  useEffect(() => {
    setIsAppLoading(ActionStore.loading || RushesStore.loading);
  }, [ActionStore.loading]);

  return (
    <div style={styles.container}>
      {isAppLoading ? <SplashScreen /> : <Outlet />}
      {isOnline === false && (
        <div style={styles.offline}>
          You are offline. Please check your internet connection.
        </div>
      )}
    </div>
  );
};

const styles: any = {
  container: {
    flex: 1,
    height: "100%",
    backgroundColor: Colors.OffWhite.primary,
  },
  offline: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    padding: Spacing.medium,
    backgroundColor: Colors.Red.primary,
    fontSize: 14,
    fontWeight: 600,
    textAlign: "center",
    color: Colors.White.primary,
    borderRadius: "8px 8px 0 0",
  },
};

export default observer(App);
