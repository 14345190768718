// Copyright 2023 LiveKit, Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

// @generated by protoc-gen-es v1.3.3 with parameter "target=ts"
// @generated from file livekit_models.proto (package livekit, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";

/**
 * @generated from enum livekit.AudioCodec
 */
export enum AudioCodec {
  /**
   * @generated from enum value: DEFAULT_AC = 0;
   */
  DEFAULT_AC = 0,

  /**
   * @generated from enum value: OPUS = 1;
   */
  OPUS = 1,

  /**
   * @generated from enum value: AAC = 2;
   */
  AAC = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(AudioCodec)
proto3.util.setEnumType(AudioCodec, "livekit.AudioCodec", [
  { no: 0, name: "DEFAULT_AC" },
  { no: 1, name: "OPUS" },
  { no: 2, name: "AAC" },
]);

/**
 * @generated from enum livekit.VideoCodec
 */
export enum VideoCodec {
  /**
   * @generated from enum value: DEFAULT_VC = 0;
   */
  DEFAULT_VC = 0,

  /**
   * @generated from enum value: H264_BASELINE = 1;
   */
  H264_BASELINE = 1,

  /**
   * @generated from enum value: H264_MAIN = 2;
   */
  H264_MAIN = 2,

  /**
   * @generated from enum value: H264_HIGH = 3;
   */
  H264_HIGH = 3,

  /**
   * @generated from enum value: VP8 = 4;
   */
  VP8 = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(VideoCodec)
proto3.util.setEnumType(VideoCodec, "livekit.VideoCodec", [
  { no: 0, name: "DEFAULT_VC" },
  { no: 1, name: "H264_BASELINE" },
  { no: 2, name: "H264_MAIN" },
  { no: 3, name: "H264_HIGH" },
  { no: 4, name: "VP8" },
]);

/**
 * @generated from enum livekit.ImageCodec
 */
export enum ImageCodec {
  /**
   * @generated from enum value: IC_DEFAULT = 0;
   */
  IC_DEFAULT = 0,

  /**
   * @generated from enum value: IC_JPEG = 1;
   */
  IC_JPEG = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(ImageCodec)
proto3.util.setEnumType(ImageCodec, "livekit.ImageCodec", [
  { no: 0, name: "IC_DEFAULT" },
  { no: 1, name: "IC_JPEG" },
]);

/**
 * @generated from enum livekit.TrackType
 */
export enum TrackType {
  /**
   * @generated from enum value: AUDIO = 0;
   */
  AUDIO = 0,

  /**
   * @generated from enum value: VIDEO = 1;
   */
  VIDEO = 1,

  /**
   * @generated from enum value: DATA = 2;
   */
  DATA = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(TrackType)
proto3.util.setEnumType(TrackType, "livekit.TrackType", [
  { no: 0, name: "AUDIO" },
  { no: 1, name: "VIDEO" },
  { no: 2, name: "DATA" },
]);

/**
 * @generated from enum livekit.TrackSource
 */
export enum TrackSource {
  /**
   * @generated from enum value: UNKNOWN = 0;
   */
  UNKNOWN = 0,

  /**
   * @generated from enum value: CAMERA = 1;
   */
  CAMERA = 1,

  /**
   * @generated from enum value: MICROPHONE = 2;
   */
  MICROPHONE = 2,

  /**
   * @generated from enum value: SCREEN_SHARE = 3;
   */
  SCREEN_SHARE = 3,

  /**
   * @generated from enum value: SCREEN_SHARE_AUDIO = 4;
   */
  SCREEN_SHARE_AUDIO = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(TrackSource)
proto3.util.setEnumType(TrackSource, "livekit.TrackSource", [
  { no: 0, name: "UNKNOWN" },
  { no: 1, name: "CAMERA" },
  { no: 2, name: "MICROPHONE" },
  { no: 3, name: "SCREEN_SHARE" },
  { no: 4, name: "SCREEN_SHARE_AUDIO" },
]);

/**
 * @generated from enum livekit.VideoQuality
 */
export enum VideoQuality {
  /**
   * @generated from enum value: LOW = 0;
   */
  LOW = 0,

  /**
   * @generated from enum value: MEDIUM = 1;
   */
  MEDIUM = 1,

  /**
   * @generated from enum value: HIGH = 2;
   */
  HIGH = 2,

  /**
   * @generated from enum value: OFF = 3;
   */
  OFF = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(VideoQuality)
proto3.util.setEnumType(VideoQuality, "livekit.VideoQuality", [
  { no: 0, name: "LOW" },
  { no: 1, name: "MEDIUM" },
  { no: 2, name: "HIGH" },
  { no: 3, name: "OFF" },
]);

/**
 * @generated from enum livekit.ConnectionQuality
 */
export enum ConnectionQuality {
  /**
   * @generated from enum value: POOR = 0;
   */
  POOR = 0,

  /**
   * @generated from enum value: GOOD = 1;
   */
  GOOD = 1,

  /**
   * @generated from enum value: EXCELLENT = 2;
   */
  EXCELLENT = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(ConnectionQuality)
proto3.util.setEnumType(ConnectionQuality, "livekit.ConnectionQuality", [
  { no: 0, name: "POOR" },
  { no: 1, name: "GOOD" },
  { no: 2, name: "EXCELLENT" },
]);

/**
 * @generated from enum livekit.ClientConfigSetting
 */
export enum ClientConfigSetting {
  /**
   * @generated from enum value: UNSET = 0;
   */
  UNSET = 0,

  /**
   * @generated from enum value: DISABLED = 1;
   */
  DISABLED = 1,

  /**
   * @generated from enum value: ENABLED = 2;
   */
  ENABLED = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(ClientConfigSetting)
proto3.util.setEnumType(ClientConfigSetting, "livekit.ClientConfigSetting", [
  { no: 0, name: "UNSET" },
  { no: 1, name: "DISABLED" },
  { no: 2, name: "ENABLED" },
]);

/**
 * @generated from enum livekit.DisconnectReason
 */
export enum DisconnectReason {
  /**
   * @generated from enum value: UNKNOWN_REASON = 0;
   */
  UNKNOWN_REASON = 0,

  /**
   * @generated from enum value: CLIENT_INITIATED = 1;
   */
  CLIENT_INITIATED = 1,

  /**
   * @generated from enum value: DUPLICATE_IDENTITY = 2;
   */
  DUPLICATE_IDENTITY = 2,

  /**
   * @generated from enum value: SERVER_SHUTDOWN = 3;
   */
  SERVER_SHUTDOWN = 3,

  /**
   * @generated from enum value: PARTICIPANT_REMOVED = 4;
   */
  PARTICIPANT_REMOVED = 4,

  /**
   * @generated from enum value: ROOM_DELETED = 5;
   */
  ROOM_DELETED = 5,

  /**
   * @generated from enum value: STATE_MISMATCH = 6;
   */
  STATE_MISMATCH = 6,

  /**
   * @generated from enum value: JOIN_FAILURE = 7;
   */
  JOIN_FAILURE = 7,
}
// Retrieve enum metadata with: proto3.getEnumType(DisconnectReason)
proto3.util.setEnumType(DisconnectReason, "livekit.DisconnectReason", [
  { no: 0, name: "UNKNOWN_REASON" },
  { no: 1, name: "CLIENT_INITIATED" },
  { no: 2, name: "DUPLICATE_IDENTITY" },
  { no: 3, name: "SERVER_SHUTDOWN" },
  { no: 4, name: "PARTICIPANT_REMOVED" },
  { no: 5, name: "ROOM_DELETED" },
  { no: 6, name: "STATE_MISMATCH" },
  { no: 7, name: "JOIN_FAILURE" },
]);

/**
 * @generated from enum livekit.ReconnectReason
 */
export enum ReconnectReason {
  /**
   * @generated from enum value: RR_UNKNOWN = 0;
   */
  RR_UNKNOWN = 0,

  /**
   * @generated from enum value: RR_SIGNAL_DISCONNECTED = 1;
   */
  RR_SIGNAL_DISCONNECTED = 1,

  /**
   * @generated from enum value: RR_PUBLISHER_FAILED = 2;
   */
  RR_PUBLISHER_FAILED = 2,

  /**
   * @generated from enum value: RR_SUBSCRIBER_FAILED = 3;
   */
  RR_SUBSCRIBER_FAILED = 3,

  /**
   * @generated from enum value: RR_SWITCH_CANDIDATE = 4;
   */
  RR_SWITCH_CANDIDATE = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(ReconnectReason)
proto3.util.setEnumType(ReconnectReason, "livekit.ReconnectReason", [
  { no: 0, name: "RR_UNKNOWN" },
  { no: 1, name: "RR_SIGNAL_DISCONNECTED" },
  { no: 2, name: "RR_PUBLISHER_FAILED" },
  { no: 3, name: "RR_SUBSCRIBER_FAILED" },
  { no: 4, name: "RR_SWITCH_CANDIDATE" },
]);

/**
 * @generated from enum livekit.SubscriptionError
 */
export enum SubscriptionError {
  /**
   * @generated from enum value: SE_UNKNOWN = 0;
   */
  SE_UNKNOWN = 0,

  /**
   * @generated from enum value: SE_CODEC_UNSUPPORTED = 1;
   */
  SE_CODEC_UNSUPPORTED = 1,

  /**
   * @generated from enum value: SE_TRACK_NOTFOUND = 2;
   */
  SE_TRACK_NOTFOUND = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(SubscriptionError)
proto3.util.setEnumType(SubscriptionError, "livekit.SubscriptionError", [
  { no: 0, name: "SE_UNKNOWN" },
  { no: 1, name: "SE_CODEC_UNSUPPORTED" },
  { no: 2, name: "SE_TRACK_NOTFOUND" },
]);

/**
 * @generated from message livekit.Room
 */
export class Room extends Message<Room> {
  /**
   * @generated from field: string sid = 1;
   */
  sid = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: uint32 empty_timeout = 3;
   */
  emptyTimeout = 0;

  /**
   * @generated from field: uint32 max_participants = 4;
   */
  maxParticipants = 0;

  /**
   * @generated from field: int64 creation_time = 5;
   */
  creationTime = protoInt64.zero;

  /**
   * @generated from field: string turn_password = 6;
   */
  turnPassword = "";

  /**
   * @generated from field: repeated livekit.Codec enabled_codecs = 7;
   */
  enabledCodecs: Codec[] = [];

  /**
   * @generated from field: string metadata = 8;
   */
  metadata = "";

  /**
   * @generated from field: uint32 num_participants = 9;
   */
  numParticipants = 0;

  /**
   * @generated from field: uint32 num_publishers = 11;
   */
  numPublishers = 0;

  /**
   * @generated from field: bool active_recording = 10;
   */
  activeRecording = false;

  constructor(data?: PartialMessage<Room>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "livekit.Room";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "sid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "empty_timeout", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 4, name: "max_participants", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 5, name: "creation_time", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "turn_password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "enabled_codecs", kind: "message", T: Codec, repeated: true },
    { no: 8, name: "metadata", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "num_participants", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 11, name: "num_publishers", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 10, name: "active_recording", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Room {
    return new Room().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Room {
    return new Room().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Room {
    return new Room().fromJsonString(jsonString, options);
  }

  static equals(a: Room | PlainMessage<Room> | undefined, b: Room | PlainMessage<Room> | undefined): boolean {
    return proto3.util.equals(Room, a, b);
  }
}

/**
 * @generated from message livekit.Codec
 */
export class Codec extends Message<Codec> {
  /**
   * @generated from field: string mime = 1;
   */
  mime = "";

  /**
   * @generated from field: string fmtp_line = 2;
   */
  fmtpLine = "";

  constructor(data?: PartialMessage<Codec>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "livekit.Codec";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "mime", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "fmtp_line", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Codec {
    return new Codec().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Codec {
    return new Codec().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Codec {
    return new Codec().fromJsonString(jsonString, options);
  }

  static equals(a: Codec | PlainMessage<Codec> | undefined, b: Codec | PlainMessage<Codec> | undefined): boolean {
    return proto3.util.equals(Codec, a, b);
  }
}

/**
 * @generated from message livekit.PlayoutDelay
 */
export class PlayoutDelay extends Message<PlayoutDelay> {
  /**
   * @generated from field: bool enabled = 1;
   */
  enabled = false;

  /**
   * @generated from field: uint32 min = 2;
   */
  min = 0;

  /**
   * @generated from field: uint32 max = 3;
   */
  max = 0;

  constructor(data?: PartialMessage<PlayoutDelay>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "livekit.PlayoutDelay";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "min", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "max", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PlayoutDelay {
    return new PlayoutDelay().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PlayoutDelay {
    return new PlayoutDelay().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PlayoutDelay {
    return new PlayoutDelay().fromJsonString(jsonString, options);
  }

  static equals(a: PlayoutDelay | PlainMessage<PlayoutDelay> | undefined, b: PlayoutDelay | PlainMessage<PlayoutDelay> | undefined): boolean {
    return proto3.util.equals(PlayoutDelay, a, b);
  }
}

/**
 * @generated from message livekit.ParticipantPermission
 */
export class ParticipantPermission extends Message<ParticipantPermission> {
  /**
   * allow participant to subscribe to other tracks in the room
   *
   * @generated from field: bool can_subscribe = 1;
   */
  canSubscribe = false;

  /**
   * allow participant to publish new tracks to room
   *
   * @generated from field: bool can_publish = 2;
   */
  canPublish = false;

  /**
   * allow participant to publish data
   *
   * @generated from field: bool can_publish_data = 3;
   */
  canPublishData = false;

  /**
   * sources that are allowed to be published
   *
   * @generated from field: repeated livekit.TrackSource can_publish_sources = 9;
   */
  canPublishSources: TrackSource[] = [];

  /**
   * indicates that it's hidden to others
   *
   * @generated from field: bool hidden = 7;
   */
  hidden = false;

  /**
   * indicates it's a recorder instance
   *
   * @generated from field: bool recorder = 8;
   */
  recorder = false;

  /**
   * indicates that participant can update own metadata
   *
   * @generated from field: bool can_update_metadata = 10;
   */
  canUpdateMetadata = false;

  constructor(data?: PartialMessage<ParticipantPermission>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "livekit.ParticipantPermission";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "can_subscribe", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "can_publish", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "can_publish_data", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "can_publish_sources", kind: "enum", T: proto3.getEnumType(TrackSource), repeated: true },
    { no: 7, name: "hidden", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "recorder", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 10, name: "can_update_metadata", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ParticipantPermission {
    return new ParticipantPermission().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ParticipantPermission {
    return new ParticipantPermission().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ParticipantPermission {
    return new ParticipantPermission().fromJsonString(jsonString, options);
  }

  static equals(a: ParticipantPermission | PlainMessage<ParticipantPermission> | undefined, b: ParticipantPermission | PlainMessage<ParticipantPermission> | undefined): boolean {
    return proto3.util.equals(ParticipantPermission, a, b);
  }
}

/**
 * @generated from message livekit.ParticipantInfo
 */
export class ParticipantInfo extends Message<ParticipantInfo> {
  /**
   * @generated from field: string sid = 1;
   */
  sid = "";

  /**
   * @generated from field: string identity = 2;
   */
  identity = "";

  /**
   * @generated from field: livekit.ParticipantInfo.State state = 3;
   */
  state = ParticipantInfo_State.JOINING;

  /**
   * @generated from field: repeated livekit.TrackInfo tracks = 4;
   */
  tracks: TrackInfo[] = [];

  /**
   * @generated from field: string metadata = 5;
   */
  metadata = "";

  /**
   * timestamp when participant joined room, in seconds
   *
   * @generated from field: int64 joined_at = 6;
   */
  joinedAt = protoInt64.zero;

  /**
   * @generated from field: string name = 9;
   */
  name = "";

  /**
   * @generated from field: uint32 version = 10;
   */
  version = 0;

  /**
   * @generated from field: livekit.ParticipantPermission permission = 11;
   */
  permission?: ParticipantPermission;

  /**
   * @generated from field: string region = 12;
   */
  region = "";

  /**
   * indicates the participant has an active publisher connection
   * and can publish to the server
   *
   * @generated from field: bool is_publisher = 13;
   */
  isPublisher = false;

  constructor(data?: PartialMessage<ParticipantInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "livekit.ParticipantInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "sid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "identity", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "state", kind: "enum", T: proto3.getEnumType(ParticipantInfo_State) },
    { no: 4, name: "tracks", kind: "message", T: TrackInfo, repeated: true },
    { no: 5, name: "metadata", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "joined_at", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 9, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "version", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 11, name: "permission", kind: "message", T: ParticipantPermission },
    { no: 12, name: "region", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "is_publisher", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ParticipantInfo {
    return new ParticipantInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ParticipantInfo {
    return new ParticipantInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ParticipantInfo {
    return new ParticipantInfo().fromJsonString(jsonString, options);
  }

  static equals(a: ParticipantInfo | PlainMessage<ParticipantInfo> | undefined, b: ParticipantInfo | PlainMessage<ParticipantInfo> | undefined): boolean {
    return proto3.util.equals(ParticipantInfo, a, b);
  }
}

/**
 * @generated from enum livekit.ParticipantInfo.State
 */
export enum ParticipantInfo_State {
  /**
   * websocket' connected, but not offered yet
   *
   * @generated from enum value: JOINING = 0;
   */
  JOINING = 0,

  /**
   * server received client offer
   *
   * @generated from enum value: JOINED = 1;
   */
  JOINED = 1,

  /**
   * ICE connectivity established
   *
   * @generated from enum value: ACTIVE = 2;
   */
  ACTIVE = 2,

  /**
   * WS disconnected
   *
   * @generated from enum value: DISCONNECTED = 3;
   */
  DISCONNECTED = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(ParticipantInfo_State)
proto3.util.setEnumType(ParticipantInfo_State, "livekit.ParticipantInfo.State", [
  { no: 0, name: "JOINING" },
  { no: 1, name: "JOINED" },
  { no: 2, name: "ACTIVE" },
  { no: 3, name: "DISCONNECTED" },
]);

/**
 * @generated from message livekit.Encryption
 */
export class Encryption extends Message<Encryption> {
  constructor(data?: PartialMessage<Encryption>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "livekit.Encryption";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Encryption {
    return new Encryption().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Encryption {
    return new Encryption().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Encryption {
    return new Encryption().fromJsonString(jsonString, options);
  }

  static equals(a: Encryption | PlainMessage<Encryption> | undefined, b: Encryption | PlainMessage<Encryption> | undefined): boolean {
    return proto3.util.equals(Encryption, a, b);
  }
}

/**
 * @generated from enum livekit.Encryption.Type
 */
export enum Encryption_Type {
  /**
   * @generated from enum value: NONE = 0;
   */
  NONE = 0,

  /**
   * @generated from enum value: GCM = 1;
   */
  GCM = 1,

  /**
   * @generated from enum value: CUSTOM = 2;
   */
  CUSTOM = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(Encryption_Type)
proto3.util.setEnumType(Encryption_Type, "livekit.Encryption.Type", [
  { no: 0, name: "NONE" },
  { no: 1, name: "GCM" },
  { no: 2, name: "CUSTOM" },
]);

/**
 * @generated from message livekit.SimulcastCodecInfo
 */
export class SimulcastCodecInfo extends Message<SimulcastCodecInfo> {
  /**
   * @generated from field: string mime_type = 1;
   */
  mimeType = "";

  /**
   * @generated from field: string mid = 2;
   */
  mid = "";

  /**
   * @generated from field: string cid = 3;
   */
  cid = "";

  /**
   * @generated from field: repeated livekit.VideoLayer layers = 4;
   */
  layers: VideoLayer[] = [];

  constructor(data?: PartialMessage<SimulcastCodecInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "livekit.SimulcastCodecInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "mime_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "mid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "cid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "layers", kind: "message", T: VideoLayer, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SimulcastCodecInfo {
    return new SimulcastCodecInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SimulcastCodecInfo {
    return new SimulcastCodecInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SimulcastCodecInfo {
    return new SimulcastCodecInfo().fromJsonString(jsonString, options);
  }

  static equals(a: SimulcastCodecInfo | PlainMessage<SimulcastCodecInfo> | undefined, b: SimulcastCodecInfo | PlainMessage<SimulcastCodecInfo> | undefined): boolean {
    return proto3.util.equals(SimulcastCodecInfo, a, b);
  }
}

/**
 * @generated from message livekit.TrackInfo
 */
export class TrackInfo extends Message<TrackInfo> {
  /**
   * @generated from field: string sid = 1;
   */
  sid = "";

  /**
   * @generated from field: livekit.TrackType type = 2;
   */
  type = TrackType.AUDIO;

  /**
   * @generated from field: string name = 3;
   */
  name = "";

  /**
   * @generated from field: bool muted = 4;
   */
  muted = false;

  /**
   * original width of video (unset for audio)
   * clients may receive a lower resolution version with simulcast
   *
   * @generated from field: uint32 width = 5;
   */
  width = 0;

  /**
   * original height of video (unset for audio)
   *
   * @generated from field: uint32 height = 6;
   */
  height = 0;

  /**
   * true if track is simulcasted
   *
   * @generated from field: bool simulcast = 7;
   */
  simulcast = false;

  /**
   * true if DTX (Discontinuous Transmission) is disabled for audio
   *
   * @generated from field: bool disable_dtx = 8;
   */
  disableDtx = false;

  /**
   * source of media
   *
   * @generated from field: livekit.TrackSource source = 9;
   */
  source = TrackSource.UNKNOWN;

  /**
   * @generated from field: repeated livekit.VideoLayer layers = 10;
   */
  layers: VideoLayer[] = [];

  /**
   * mime type of codec
   *
   * @generated from field: string mime_type = 11;
   */
  mimeType = "";

  /**
   * @generated from field: string mid = 12;
   */
  mid = "";

  /**
   * @generated from field: repeated livekit.SimulcastCodecInfo codecs = 13;
   */
  codecs: SimulcastCodecInfo[] = [];

  /**
   * @generated from field: bool stereo = 14;
   */
  stereo = false;

  /**
   * true if RED (Redundant Encoding) is disabled for audio
   *
   * @generated from field: bool disable_red = 15;
   */
  disableRed = false;

  /**
   * @generated from field: livekit.Encryption.Type encryption = 16;
   */
  encryption = Encryption_Type.NONE;

  /**
   * @generated from field: string stream = 17;
   */
  stream = "";

  constructor(data?: PartialMessage<TrackInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "livekit.TrackInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "sid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "type", kind: "enum", T: proto3.getEnumType(TrackType) },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "muted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "width", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 6, name: "height", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 7, name: "simulcast", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "disable_dtx", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "source", kind: "enum", T: proto3.getEnumType(TrackSource) },
    { no: 10, name: "layers", kind: "message", T: VideoLayer, repeated: true },
    { no: 11, name: "mime_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "mid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "codecs", kind: "message", T: SimulcastCodecInfo, repeated: true },
    { no: 14, name: "stereo", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 15, name: "disable_red", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 16, name: "encryption", kind: "enum", T: proto3.getEnumType(Encryption_Type) },
    { no: 17, name: "stream", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TrackInfo {
    return new TrackInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TrackInfo {
    return new TrackInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TrackInfo {
    return new TrackInfo().fromJsonString(jsonString, options);
  }

  static equals(a: TrackInfo | PlainMessage<TrackInfo> | undefined, b: TrackInfo | PlainMessage<TrackInfo> | undefined): boolean {
    return proto3.util.equals(TrackInfo, a, b);
  }
}

/**
 * provide information about available spatial layers
 *
 * @generated from message livekit.VideoLayer
 */
export class VideoLayer extends Message<VideoLayer> {
  /**
   * for tracks with a single layer, this should be HIGH
   *
   * @generated from field: livekit.VideoQuality quality = 1;
   */
  quality = VideoQuality.LOW;

  /**
   * @generated from field: uint32 width = 2;
   */
  width = 0;

  /**
   * @generated from field: uint32 height = 3;
   */
  height = 0;

  /**
   * target bitrate in bit per second (bps), server will measure actual
   *
   * @generated from field: uint32 bitrate = 4;
   */
  bitrate = 0;

  /**
   * @generated from field: uint32 ssrc = 5;
   */
  ssrc = 0;

  constructor(data?: PartialMessage<VideoLayer>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "livekit.VideoLayer";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "quality", kind: "enum", T: proto3.getEnumType(VideoQuality) },
    { no: 2, name: "width", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "height", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 4, name: "bitrate", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 5, name: "ssrc", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VideoLayer {
    return new VideoLayer().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VideoLayer {
    return new VideoLayer().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VideoLayer {
    return new VideoLayer().fromJsonString(jsonString, options);
  }

  static equals(a: VideoLayer | PlainMessage<VideoLayer> | undefined, b: VideoLayer | PlainMessage<VideoLayer> | undefined): boolean {
    return proto3.util.equals(VideoLayer, a, b);
  }
}

/**
 * new DataPacket API
 *
 * @generated from message livekit.DataPacket
 */
export class DataPacket extends Message<DataPacket> {
  /**
   * @generated from field: livekit.DataPacket.Kind kind = 1;
   */
  kind = DataPacket_Kind.RELIABLE;

  /**
   * @generated from oneof livekit.DataPacket.value
   */
  value: {
    /**
     * @generated from field: livekit.UserPacket user = 2;
     */
    value: UserPacket;
    case: "user";
  } | {
    /**
     * @generated from field: livekit.ActiveSpeakerUpdate speaker = 3;
     */
    value: ActiveSpeakerUpdate;
    case: "speaker";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<DataPacket>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "livekit.DataPacket";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "kind", kind: "enum", T: proto3.getEnumType(DataPacket_Kind) },
    { no: 2, name: "user", kind: "message", T: UserPacket, oneof: "value" },
    { no: 3, name: "speaker", kind: "message", T: ActiveSpeakerUpdate, oneof: "value" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DataPacket {
    return new DataPacket().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DataPacket {
    return new DataPacket().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DataPacket {
    return new DataPacket().fromJsonString(jsonString, options);
  }

  static equals(a: DataPacket | PlainMessage<DataPacket> | undefined, b: DataPacket | PlainMessage<DataPacket> | undefined): boolean {
    return proto3.util.equals(DataPacket, a, b);
  }
}

/**
 * @generated from enum livekit.DataPacket.Kind
 */
export enum DataPacket_Kind {
  /**
   * @generated from enum value: RELIABLE = 0;
   */
  RELIABLE = 0,

  /**
   * @generated from enum value: LOSSY = 1;
   */
  LOSSY = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(DataPacket_Kind)
proto3.util.setEnumType(DataPacket_Kind, "livekit.DataPacket.Kind", [
  { no: 0, name: "RELIABLE" },
  { no: 1, name: "LOSSY" },
]);

/**
 * @generated from message livekit.ActiveSpeakerUpdate
 */
export class ActiveSpeakerUpdate extends Message<ActiveSpeakerUpdate> {
  /**
   * @generated from field: repeated livekit.SpeakerInfo speakers = 1;
   */
  speakers: SpeakerInfo[] = [];

  constructor(data?: PartialMessage<ActiveSpeakerUpdate>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "livekit.ActiveSpeakerUpdate";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "speakers", kind: "message", T: SpeakerInfo, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ActiveSpeakerUpdate {
    return new ActiveSpeakerUpdate().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ActiveSpeakerUpdate {
    return new ActiveSpeakerUpdate().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ActiveSpeakerUpdate {
    return new ActiveSpeakerUpdate().fromJsonString(jsonString, options);
  }

  static equals(a: ActiveSpeakerUpdate | PlainMessage<ActiveSpeakerUpdate> | undefined, b: ActiveSpeakerUpdate | PlainMessage<ActiveSpeakerUpdate> | undefined): boolean {
    return proto3.util.equals(ActiveSpeakerUpdate, a, b);
  }
}

/**
 * @generated from message livekit.SpeakerInfo
 */
export class SpeakerInfo extends Message<SpeakerInfo> {
  /**
   * @generated from field: string sid = 1;
   */
  sid = "";

  /**
   * audio level, 0-1.0, 1 is loudest
   *
   * @generated from field: float level = 2;
   */
  level = 0;

  /**
   * true if speaker is currently active
   *
   * @generated from field: bool active = 3;
   */
  active = false;

  constructor(data?: PartialMessage<SpeakerInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "livekit.SpeakerInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "sid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "level", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 3, name: "active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SpeakerInfo {
    return new SpeakerInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SpeakerInfo {
    return new SpeakerInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SpeakerInfo {
    return new SpeakerInfo().fromJsonString(jsonString, options);
  }

  static equals(a: SpeakerInfo | PlainMessage<SpeakerInfo> | undefined, b: SpeakerInfo | PlainMessage<SpeakerInfo> | undefined): boolean {
    return proto3.util.equals(SpeakerInfo, a, b);
  }
}

/**
 * @generated from message livekit.UserPacket
 */
export class UserPacket extends Message<UserPacket> {
  /**
   * participant ID of user that sent the message
   *
   * @generated from field: string participant_sid = 1;
   */
  participantSid = "";

  /**
   * @generated from field: string participant_identity = 5;
   */
  participantIdentity = "";

  /**
   * user defined payload
   *
   * @generated from field: bytes payload = 2;
   */
  payload = new Uint8Array(0);

  /**
   * the ID of the participants who will receive the message (sent to all by default)
   *
   * @generated from field: repeated string destination_sids = 3;
   */
  destinationSids: string[] = [];

  /**
   * identities of participants who will receive the message (sent to all by default)
   *
   * @generated from field: repeated string destination_identities = 6;
   */
  destinationIdentities: string[] = [];

  /**
   * topic under which the message was published
   *
   * @generated from field: optional string topic = 4;
   */
  topic?: string;

  constructor(data?: PartialMessage<UserPacket>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "livekit.UserPacket";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "participant_sid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "participant_identity", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "payload", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 3, name: "destination_sids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "destination_identities", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "topic", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserPacket {
    return new UserPacket().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserPacket {
    return new UserPacket().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserPacket {
    return new UserPacket().fromJsonString(jsonString, options);
  }

  static equals(a: UserPacket | PlainMessage<UserPacket> | undefined, b: UserPacket | PlainMessage<UserPacket> | undefined): boolean {
    return proto3.util.equals(UserPacket, a, b);
  }
}

/**
 * @generated from message livekit.ParticipantTracks
 */
export class ParticipantTracks extends Message<ParticipantTracks> {
  /**
   * participant ID of participant to whom the tracks belong
   *
   * @generated from field: string participant_sid = 1;
   */
  participantSid = "";

  /**
   * @generated from field: repeated string track_sids = 2;
   */
  trackSids: string[] = [];

  constructor(data?: PartialMessage<ParticipantTracks>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "livekit.ParticipantTracks";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "participant_sid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "track_sids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ParticipantTracks {
    return new ParticipantTracks().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ParticipantTracks {
    return new ParticipantTracks().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ParticipantTracks {
    return new ParticipantTracks().fromJsonString(jsonString, options);
  }

  static equals(a: ParticipantTracks | PlainMessage<ParticipantTracks> | undefined, b: ParticipantTracks | PlainMessage<ParticipantTracks> | undefined): boolean {
    return proto3.util.equals(ParticipantTracks, a, b);
  }
}

/**
 * details about the server
 *
 * @generated from message livekit.ServerInfo
 */
export class ServerInfo extends Message<ServerInfo> {
  /**
   * @generated from field: livekit.ServerInfo.Edition edition = 1;
   */
  edition = ServerInfo_Edition.Standard;

  /**
   * @generated from field: string version = 2;
   */
  version = "";

  /**
   * @generated from field: int32 protocol = 3;
   */
  protocol = 0;

  /**
   * @generated from field: string region = 4;
   */
  region = "";

  /**
   * @generated from field: string node_id = 5;
   */
  nodeId = "";

  /**
   * additional debugging information. sent only if server is in development mode
   *
   * @generated from field: string debug_info = 6;
   */
  debugInfo = "";

  constructor(data?: PartialMessage<ServerInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "livekit.ServerInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "edition", kind: "enum", T: proto3.getEnumType(ServerInfo_Edition) },
    { no: 2, name: "version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "protocol", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "region", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "node_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "debug_info", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ServerInfo {
    return new ServerInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ServerInfo {
    return new ServerInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ServerInfo {
    return new ServerInfo().fromJsonString(jsonString, options);
  }

  static equals(a: ServerInfo | PlainMessage<ServerInfo> | undefined, b: ServerInfo | PlainMessage<ServerInfo> | undefined): boolean {
    return proto3.util.equals(ServerInfo, a, b);
  }
}

/**
 * @generated from enum livekit.ServerInfo.Edition
 */
export enum ServerInfo_Edition {
  /**
   * @generated from enum value: Standard = 0;
   */
  Standard = 0,

  /**
   * @generated from enum value: Cloud = 1;
   */
  Cloud = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(ServerInfo_Edition)
proto3.util.setEnumType(ServerInfo_Edition, "livekit.ServerInfo.Edition", [
  { no: 0, name: "Standard" },
  { no: 1, name: "Cloud" },
]);

/**
 * details about the client
 *
 * @generated from message livekit.ClientInfo
 */
export class ClientInfo extends Message<ClientInfo> {
  /**
   * @generated from field: livekit.ClientInfo.SDK sdk = 1;
   */
  sdk = ClientInfo_SDK.UNKNOWN;

  /**
   * @generated from field: string version = 2;
   */
  version = "";

  /**
   * @generated from field: int32 protocol = 3;
   */
  protocol = 0;

  /**
   * @generated from field: string os = 4;
   */
  os = "";

  /**
   * @generated from field: string os_version = 5;
   */
  osVersion = "";

  /**
   * @generated from field: string device_model = 6;
   */
  deviceModel = "";

  /**
   * @generated from field: string browser = 7;
   */
  browser = "";

  /**
   * @generated from field: string browser_version = 8;
   */
  browserVersion = "";

  /**
   * @generated from field: string address = 9;
   */
  address = "";

  /**
   * wifi, wired, cellular, vpn, empty if not known
   *
   * @generated from field: string network = 10;
   */
  network = "";

  constructor(data?: PartialMessage<ClientInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "livekit.ClientInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "sdk", kind: "enum", T: proto3.getEnumType(ClientInfo_SDK) },
    { no: 2, name: "version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "protocol", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "os", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "os_version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "device_model", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "browser", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "browser_version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "network", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ClientInfo {
    return new ClientInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ClientInfo {
    return new ClientInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ClientInfo {
    return new ClientInfo().fromJsonString(jsonString, options);
  }

  static equals(a: ClientInfo | PlainMessage<ClientInfo> | undefined, b: ClientInfo | PlainMessage<ClientInfo> | undefined): boolean {
    return proto3.util.equals(ClientInfo, a, b);
  }
}

/**
 * @generated from enum livekit.ClientInfo.SDK
 */
export enum ClientInfo_SDK {
  /**
   * @generated from enum value: UNKNOWN = 0;
   */
  UNKNOWN = 0,

  /**
   * @generated from enum value: JS = 1;
   */
  JS = 1,

  /**
   * @generated from enum value: SWIFT = 2;
   */
  SWIFT = 2,

  /**
   * @generated from enum value: ANDROID = 3;
   */
  ANDROID = 3,

  /**
   * @generated from enum value: FLUTTER = 4;
   */
  FLUTTER = 4,

  /**
   * @generated from enum value: GO = 5;
   */
  GO = 5,

  /**
   * @generated from enum value: UNITY = 6;
   */
  UNITY = 6,

  /**
   * @generated from enum value: REACT_NATIVE = 7;
   */
  REACT_NATIVE = 7,

  /**
   * @generated from enum value: RUST = 8;
   */
  RUST = 8,

  /**
   * @generated from enum value: PYTHON = 9;
   */
  PYTHON = 9,

  /**
   * @generated from enum value: CPP = 10;
   */
  CPP = 10,
}
// Retrieve enum metadata with: proto3.getEnumType(ClientInfo_SDK)
proto3.util.setEnumType(ClientInfo_SDK, "livekit.ClientInfo.SDK", [
  { no: 0, name: "UNKNOWN" },
  { no: 1, name: "JS" },
  { no: 2, name: "SWIFT" },
  { no: 3, name: "ANDROID" },
  { no: 4, name: "FLUTTER" },
  { no: 5, name: "GO" },
  { no: 6, name: "UNITY" },
  { no: 7, name: "REACT_NATIVE" },
  { no: 8, name: "RUST" },
  { no: 9, name: "PYTHON" },
  { no: 10, name: "CPP" },
]);

/**
 * server provided client configuration
 *
 * @generated from message livekit.ClientConfiguration
 */
export class ClientConfiguration extends Message<ClientConfiguration> {
  /**
   * @generated from field: livekit.VideoConfiguration video = 1;
   */
  video?: VideoConfiguration;

  /**
   * @generated from field: livekit.VideoConfiguration screen = 2;
   */
  screen?: VideoConfiguration;

  /**
   * @generated from field: livekit.ClientConfigSetting resume_connection = 3;
   */
  resumeConnection = ClientConfigSetting.UNSET;

  /**
   * @generated from field: livekit.DisabledCodecs disabled_codecs = 4;
   */
  disabledCodecs?: DisabledCodecs;

  /**
   * @generated from field: livekit.ClientConfigSetting force_relay = 5;
   */
  forceRelay = ClientConfigSetting.UNSET;

  constructor(data?: PartialMessage<ClientConfiguration>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "livekit.ClientConfiguration";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "video", kind: "message", T: VideoConfiguration },
    { no: 2, name: "screen", kind: "message", T: VideoConfiguration },
    { no: 3, name: "resume_connection", kind: "enum", T: proto3.getEnumType(ClientConfigSetting) },
    { no: 4, name: "disabled_codecs", kind: "message", T: DisabledCodecs },
    { no: 5, name: "force_relay", kind: "enum", T: proto3.getEnumType(ClientConfigSetting) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ClientConfiguration {
    return new ClientConfiguration().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ClientConfiguration {
    return new ClientConfiguration().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ClientConfiguration {
    return new ClientConfiguration().fromJsonString(jsonString, options);
  }

  static equals(a: ClientConfiguration | PlainMessage<ClientConfiguration> | undefined, b: ClientConfiguration | PlainMessage<ClientConfiguration> | undefined): boolean {
    return proto3.util.equals(ClientConfiguration, a, b);
  }
}

/**
 * @generated from message livekit.VideoConfiguration
 */
export class VideoConfiguration extends Message<VideoConfiguration> {
  /**
   * @generated from field: livekit.ClientConfigSetting hardware_encoder = 1;
   */
  hardwareEncoder = ClientConfigSetting.UNSET;

  constructor(data?: PartialMessage<VideoConfiguration>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "livekit.VideoConfiguration";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "hardware_encoder", kind: "enum", T: proto3.getEnumType(ClientConfigSetting) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VideoConfiguration {
    return new VideoConfiguration().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VideoConfiguration {
    return new VideoConfiguration().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VideoConfiguration {
    return new VideoConfiguration().fromJsonString(jsonString, options);
  }

  static equals(a: VideoConfiguration | PlainMessage<VideoConfiguration> | undefined, b: VideoConfiguration | PlainMessage<VideoConfiguration> | undefined): boolean {
    return proto3.util.equals(VideoConfiguration, a, b);
  }
}

/**
 * @generated from message livekit.DisabledCodecs
 */
export class DisabledCodecs extends Message<DisabledCodecs> {
  /**
   * disabled for both publish and subscribe
   *
   * @generated from field: repeated livekit.Codec codecs = 1;
   */
  codecs: Codec[] = [];

  /**
   * only disable for publish
   *
   * @generated from field: repeated livekit.Codec publish = 2;
   */
  publish: Codec[] = [];

  constructor(data?: PartialMessage<DisabledCodecs>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "livekit.DisabledCodecs";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "codecs", kind: "message", T: Codec, repeated: true },
    { no: 2, name: "publish", kind: "message", T: Codec, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DisabledCodecs {
    return new DisabledCodecs().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DisabledCodecs {
    return new DisabledCodecs().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DisabledCodecs {
    return new DisabledCodecs().fromJsonString(jsonString, options);
  }

  static equals(a: DisabledCodecs | PlainMessage<DisabledCodecs> | undefined, b: DisabledCodecs | PlainMessage<DisabledCodecs> | undefined): boolean {
    return proto3.util.equals(DisabledCodecs, a, b);
  }
}

/**
 * @generated from message livekit.RTPDrift
 */
export class RTPDrift extends Message<RTPDrift> {
  /**
   * @generated from field: google.protobuf.Timestamp start_time = 1;
   */
  startTime?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp end_time = 2;
   */
  endTime?: Timestamp;

  /**
   * @generated from field: double duration = 3;
   */
  duration = 0;

  /**
   * @generated from field: uint64 start_timestamp = 4;
   */
  startTimestamp = protoInt64.zero;

  /**
   * @generated from field: uint64 end_timestamp = 5;
   */
  endTimestamp = protoInt64.zero;

  /**
   * @generated from field: uint64 rtp_clock_ticks = 6;
   */
  rtpClockTicks = protoInt64.zero;

  /**
   * @generated from field: int64 drift_samples = 7;
   */
  driftSamples = protoInt64.zero;

  /**
   * @generated from field: double drift_ms = 8;
   */
  driftMs = 0;

  /**
   * @generated from field: double clock_rate = 9;
   */
  clockRate = 0;

  constructor(data?: PartialMessage<RTPDrift>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "livekit.RTPDrift";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "start_time", kind: "message", T: Timestamp },
    { no: 2, name: "end_time", kind: "message", T: Timestamp },
    { no: 3, name: "duration", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "start_timestamp", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 5, name: "end_timestamp", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 6, name: "rtp_clock_ticks", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 7, name: "drift_samples", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 8, name: "drift_ms", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 9, name: "clock_rate", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RTPDrift {
    return new RTPDrift().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RTPDrift {
    return new RTPDrift().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RTPDrift {
    return new RTPDrift().fromJsonString(jsonString, options);
  }

  static equals(a: RTPDrift | PlainMessage<RTPDrift> | undefined, b: RTPDrift | PlainMessage<RTPDrift> | undefined): boolean {
    return proto3.util.equals(RTPDrift, a, b);
  }
}

/**
 * @generated from message livekit.RTPStats
 */
export class RTPStats extends Message<RTPStats> {
  /**
   * @generated from field: google.protobuf.Timestamp start_time = 1;
   */
  startTime?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp end_time = 2;
   */
  endTime?: Timestamp;

  /**
   * @generated from field: double duration = 3;
   */
  duration = 0;

  /**
   * @generated from field: uint32 packets = 4;
   */
  packets = 0;

  /**
   * @generated from field: double packet_rate = 5;
   */
  packetRate = 0;

  /**
   * @generated from field: uint64 bytes = 6;
   */
  bytes = protoInt64.zero;

  /**
   * @generated from field: uint64 header_bytes = 39;
   */
  headerBytes = protoInt64.zero;

  /**
   * @generated from field: double bitrate = 7;
   */
  bitrate = 0;

  /**
   * @generated from field: uint32 packets_lost = 8;
   */
  packetsLost = 0;

  /**
   * @generated from field: double packet_loss_rate = 9;
   */
  packetLossRate = 0;

  /**
   * @generated from field: float packet_loss_percentage = 10;
   */
  packetLossPercentage = 0;

  /**
   * @generated from field: uint32 packets_duplicate = 11;
   */
  packetsDuplicate = 0;

  /**
   * @generated from field: double packet_duplicate_rate = 12;
   */
  packetDuplicateRate = 0;

  /**
   * @generated from field: uint64 bytes_duplicate = 13;
   */
  bytesDuplicate = protoInt64.zero;

  /**
   * @generated from field: uint64 header_bytes_duplicate = 40;
   */
  headerBytesDuplicate = protoInt64.zero;

  /**
   * @generated from field: double bitrate_duplicate = 14;
   */
  bitrateDuplicate = 0;

  /**
   * @generated from field: uint32 packets_padding = 15;
   */
  packetsPadding = 0;

  /**
   * @generated from field: double packet_padding_rate = 16;
   */
  packetPaddingRate = 0;

  /**
   * @generated from field: uint64 bytes_padding = 17;
   */
  bytesPadding = protoInt64.zero;

  /**
   * @generated from field: uint64 header_bytes_padding = 41;
   */
  headerBytesPadding = protoInt64.zero;

  /**
   * @generated from field: double bitrate_padding = 18;
   */
  bitratePadding = 0;

  /**
   * @generated from field: uint32 packets_out_of_order = 19;
   */
  packetsOutOfOrder = 0;

  /**
   * @generated from field: uint32 frames = 20;
   */
  frames = 0;

  /**
   * @generated from field: double frame_rate = 21;
   */
  frameRate = 0;

  /**
   * @generated from field: double jitter_current = 22;
   */
  jitterCurrent = 0;

  /**
   * @generated from field: double jitter_max = 23;
   */
  jitterMax = 0;

  /**
   * @generated from field: map<int32, uint32> gap_histogram = 24;
   */
  gapHistogram: { [key: number]: number } = {};

  /**
   * @generated from field: uint32 nacks = 25;
   */
  nacks = 0;

  /**
   * @generated from field: uint32 nack_acks = 37;
   */
  nackAcks = 0;

  /**
   * @generated from field: uint32 nack_misses = 26;
   */
  nackMisses = 0;

  /**
   * @generated from field: uint32 nack_repeated = 38;
   */
  nackRepeated = 0;

  /**
   * @generated from field: uint32 plis = 27;
   */
  plis = 0;

  /**
   * @generated from field: google.protobuf.Timestamp last_pli = 28;
   */
  lastPli?: Timestamp;

  /**
   * @generated from field: uint32 firs = 29;
   */
  firs = 0;

  /**
   * @generated from field: google.protobuf.Timestamp last_fir = 30;
   */
  lastFir?: Timestamp;

  /**
   * @generated from field: uint32 rtt_current = 31;
   */
  rttCurrent = 0;

  /**
   * @generated from field: uint32 rtt_max = 32;
   */
  rttMax = 0;

  /**
   * @generated from field: uint32 key_frames = 33;
   */
  keyFrames = 0;

  /**
   * @generated from field: google.protobuf.Timestamp last_key_frame = 34;
   */
  lastKeyFrame?: Timestamp;

  /**
   * @generated from field: uint32 layer_lock_plis = 35;
   */
  layerLockPlis = 0;

  /**
   * @generated from field: google.protobuf.Timestamp last_layer_lock_pli = 36;
   */
  lastLayerLockPli?: Timestamp;

  /**
   * @generated from field: livekit.RTPDrift packet_drift = 44;
   */
  packetDrift?: RTPDrift;

  /**
   * NEXT_ID: 46
   *
   * @generated from field: livekit.RTPDrift report_drift = 45;
   */
  reportDrift?: RTPDrift;

  constructor(data?: PartialMessage<RTPStats>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "livekit.RTPStats";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "start_time", kind: "message", T: Timestamp },
    { no: 2, name: "end_time", kind: "message", T: Timestamp },
    { no: 3, name: "duration", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "packets", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 5, name: "packet_rate", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 6, name: "bytes", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 39, name: "header_bytes", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 7, name: "bitrate", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 8, name: "packets_lost", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 9, name: "packet_loss_rate", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 10, name: "packet_loss_percentage", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 11, name: "packets_duplicate", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 12, name: "packet_duplicate_rate", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 13, name: "bytes_duplicate", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 40, name: "header_bytes_duplicate", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 14, name: "bitrate_duplicate", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 15, name: "packets_padding", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 16, name: "packet_padding_rate", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 17, name: "bytes_padding", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 41, name: "header_bytes_padding", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 18, name: "bitrate_padding", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 19, name: "packets_out_of_order", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 20, name: "frames", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 21, name: "frame_rate", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 22, name: "jitter_current", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 23, name: "jitter_max", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 24, name: "gap_histogram", kind: "map", K: 5 /* ScalarType.INT32 */, V: {kind: "scalar", T: 13 /* ScalarType.UINT32 */} },
    { no: 25, name: "nacks", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 37, name: "nack_acks", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 26, name: "nack_misses", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 38, name: "nack_repeated", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 27, name: "plis", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 28, name: "last_pli", kind: "message", T: Timestamp },
    { no: 29, name: "firs", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 30, name: "last_fir", kind: "message", T: Timestamp },
    { no: 31, name: "rtt_current", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 32, name: "rtt_max", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 33, name: "key_frames", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 34, name: "last_key_frame", kind: "message", T: Timestamp },
    { no: 35, name: "layer_lock_plis", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 36, name: "last_layer_lock_pli", kind: "message", T: Timestamp },
    { no: 44, name: "packet_drift", kind: "message", T: RTPDrift },
    { no: 45, name: "report_drift", kind: "message", T: RTPDrift },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RTPStats {
    return new RTPStats().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RTPStats {
    return new RTPStats().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RTPStats {
    return new RTPStats().fromJsonString(jsonString, options);
  }

  static equals(a: RTPStats | PlainMessage<RTPStats> | undefined, b: RTPStats | PlainMessage<RTPStats> | undefined): boolean {
    return proto3.util.equals(RTPStats, a, b);
  }
}

/**
 * @generated from message livekit.TimedVersion
 */
export class TimedVersion extends Message<TimedVersion> {
  /**
   * @generated from field: int64 unix_micro = 1;
   */
  unixMicro = protoInt64.zero;

  /**
   * @generated from field: int32 ticks = 2;
   */
  ticks = 0;

  constructor(data?: PartialMessage<TimedVersion>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "livekit.TimedVersion";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "unix_micro", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "ticks", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TimedVersion {
    return new TimedVersion().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TimedVersion {
    return new TimedVersion().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TimedVersion {
    return new TimedVersion().fromJsonString(jsonString, options);
  }

  static equals(a: TimedVersion | PlainMessage<TimedVersion> | undefined, b: TimedVersion | PlainMessage<TimedVersion> | undefined): boolean {
    return proto3.util.equals(TimedVersion, a, b);
  }
}

