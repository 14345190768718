import React, { useEffect, useMemo, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import { LiveKitRoom } from "@livekit/components-react";
import { RoomOptions, VideoPreset, VideoPresets } from "livekit-client";

import { getLiveKitAccessToken } from "src/api/video-studio-server";
import { Colors } from "src/styles/variables";
import LoadingAnimation from "src/components/svg/loading-animation";

const RecordingRoom = () => {
  const { actionId } = useParams();
  const [accessToken, setAccessToken] = useState<string>();
  const [wsUrl, setWsUrl] = useState<string>();

  useEffect(() => {
    if (!actionId) {
      return;
    }
    onMount();
  }, [actionId]);

  const liveKitRoomOptions: RoomOptions = useMemo(() => {
    return {
      adaptiveStream: {
        pauseVideoInBackground: true,
        pixelDensity: "screen",
      },
      dynacast: true,
      videoCaptureDefaults: {
        resolution: VideoPresets.h720,
      },
    };
  }, []);

  const onMount = async () => {
    if (!actionId) {
      return;
    }
    const { accessToken, wsUrl } = await getLiveKitAccessToken(actionId);
    setAccessToken(accessToken);
    setWsUrl(wsUrl);
  };

  if (!accessToken || !wsUrl) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          backgroundColor: Colors.Magenta[50],
        }}
      >
        <LoadingAnimation
          fill={Colors.Magenta.primary}
          width={60}
          height={60}
        />
      </div>
    );
  }

  return (
    <LiveKitRoom
      serverUrl={wsUrl}
      token={accessToken}
      connect={true}
      audio={true}
      video={true}
      style={{
        height: "100%",
        width: "100%",
        background: Colors.Magenta[50],
      }}
      options={liveKitRoomOptions}
      data-theme="default"
    >
      <Outlet />
    </LiveKitRoom>
  );
};

export default RecordingRoom;
